<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style lang="scss">
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
::-webkit-scrollbar {
    width: 6px;

    height: 6px;

    background-color: #f5f5f5;
}

/*定义滚动条轨道

内阴影+圆角*/

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);

    border-radius: 10px;

    background-color: #f5f5f5;
}

/*定义滑块

内阴影+圆角*/

::-webkit-scrollbar-thumb {
    border-radius: 10px;

    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);

    background-color: #999;
}

.ql-picker.ql-lineheight .ql-picker-label::before,
.ql-picker.ql-lineheight .ql-picker-item::before {
    content: "行高";
}

.ql-picker.ql-lineheight .ql-picker-label[data-value="1"]::before,
.ql-picker.ql-lineheight .ql-picker-item[data-value="1"]::before {
    content: "1";
}

.ql-picker.ql-lineheight .ql-picker-label[data-value="1-5"]::before,
.ql-picker.ql-lineheight .ql-picker-item[data-value="1-5"]::before {
    content: "1.5";
}

.ql-picker.ql-lineheight .ql-picker-label[data-value="1-75"]::before,
.ql-picker.ql-lineheight .ql-picker-item[data-value="1-75"]::before {
    content: "1.75";
}

.ql-picker.ql-lineheight .ql-picker-label[data-value="2"]::before,
.ql-picker.ql-lineheight .ql-picker-item[data-value="2"]::before {
    content: "2";
}

.ql-picker.ql-lineheight .ql-picker-label[data-value="3"]::before,
.ql-picker.ql-lineheight .ql-picker-item[data-value="3"]::before {
    content: "3";
}

.ql-picker.ql-lineheight .ql-picker-label[data-value="4"]::before,
.ql-picker.ql-lineheight .ql-picker-item[data-value="4"]::before {
    content: "4";
}

.ql-picker.ql-lineheight .ql-picker-label[data-value="5"]::before,
.ql-picker.ql-lineheight .ql-picker-item[data-value="5"]::before {
    content: "5";
}

.ql-picker.ql-lineheight {
    width: 70px;
}

.ql-editor .ql-lineheight-1 {
    line-height: 1;
}

.ql-editor .ql-lineheight-1-5 {
    line-height: 1.5;
}

.ql-editor .ql-lineheight-1-75 {
    line-height: 1.75;
}

.ql-editor .ql-lineheight-2 {
    line-height: 2;
}

.ql-editor .ql-lineheight-3 {
    line-height: 3;
}

.ql-editor .ql-lineheight-4 {
    line-height: 4;
}

.ql-editor .ql-lineheight-5 {
    line-height: 5;
}
</style>
