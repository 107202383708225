import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "",
      component: () => import("@/views/index.vue"),
      redirect: "/home",
      children: [
        {
          path: "/home",
          name: "首页",
          component: () => import("@/views/home.vue")
        },
        {
          path: "/recruit",
          name: "招募调解员",
          component: () => import("@/views/recruit.vue")
        },
        {
          path: "/help",
          name: "债务人帮扶",
          component: () => import("@/views/help.vue")
        },
        {
          path: "/mediate",
          name: "调解指南",
          component: () => import("@/views/mediate.vue")
        },
        {
          path: "/service",
          name: "服务支持",
          component: () => import("@/views/service.vue")
        },
        {
          path: "/contact",
          name: "联系我们",
          component: () => import("@/views/contact.vue")
        },
        {
          path: "/detail",
          name: "新闻详情",
          component: () => import("@/views/detail.vue")
        },
      ]
    }
  ]
});
